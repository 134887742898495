<template>
  <div>
     <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="안전점검 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable && !disabled && !data.sysApprovalRequestId"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-plant
                  required
                  :disabled="disabled || updateMode"
                  :editable="editable"
                  :plantCd="data.plantCd"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd"
                  @datachange="datachange('A')">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-datepicker
                  required
                  :disabled="disabled"
                  :editable="editable"
                  label="점검년월"
                  type="month"
                  name="checkDate"
                  v-model="data.checkDate"
                  @datachange="datachange('B')">
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-dept
                  :disabled="disabled"
                  :editable="editable"
                  label="점검부서"
                  name="checkDeptCd"
                  v-model="data.checkDeptCd"
                  @setDeptName="setDeptName">
                </c-dept>
              </div>
              <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :disabled="disabled"
                  :editable="editable"
                  label="점검부서(입력란)"
                  name="checkUserId"
                  v-model="data.checkUserId">
                </c-text>
              </div> -->
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-tab
            type="tabcard"
            :tabItems.sync="tabItems"
            :inlineLabel="true"
            v-model="tab"
            align="left"
          >
            <template v-slot:default="tab">
              <component
                :is="tab.component"
                :data="data"
                :grid.sync="grid"
                :contentHeight="contentHeight"
                @getDetail="getDetail"
                @setKey="setKey"
              />
            </template>
          </c-tab>
        </div>
      </div>
      <c-dialog :param="popupOptions">></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safety-check-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        safetyCheckId: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      year: '',
      grid: {
        columns: [],
        data: [],
        height: 'auto',
      },
      data: {
        safetyCheckId: '',  // 열화점검 일련 번호
        plantCd: '',  // 사업장 코드
        checkStatusCd: '',  // 계획수립/점검중/점검완료
        checkDeptCd: '',  // 점검부서
        checkUserId: '',  // 점검자 ID
        checkDate: '',  // 점검월
        safetyCheckName: '',  // 점검명
        evaluationCompleteFlag: 'N',  // 결재완료 후 평가완료 처리
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        safetyCheckResultModelList: [], // sheet
        safetyCheckImproveModelList: [], // 개선 list
        safetyCheckUserModelList: [],
      },
      popupOptions: {
        isFull: true,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      updateMode: false,
      editable: true,
      saveUrl: transactionConfig.sop.safetycheck.plan.insert.url,
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      completeUrl: '',
      isSave: false,
      isComplete: false,
      isApproval: false,
      mappingType: 'POST',
      getUrl: '',
      resultItemDetail: '',
      currentMonth: '',
      lunarHolidays: [],
      today: '',
      tab: 'safetyCheckResult',
      tabItems: [
        { name: 'safetyCheckResult', icon: 'list', label: '안전점검 결과', component: () => import(`${'@/pages/sop/mim/smd/safetyCheckResult.vue'}`) },
        { name: 'safetyCheckImpr', icon: 'health_and_safety', label: '개선관리', component: () => import(`${'@/pages/sop/mim/smd/safetyCheckImpr.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    approvalInfo() {
      return {
        sysApprovalRequestId: this.data.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.data.approvalStatusCd, 
        apprEditable: this.data.checkStatusCd && this.data.checkStatusCd !== 'MCSC000015', // 결재버튼 활성화 기준
        param: this.data, // 결재 param
        approvalUrl: this.updateUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000008', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          safetyCheckId: this.data.safetyCheckId,
          isApprContent: true
        },
        approvalRequestName: '안전점검 ['+this.data.safetyCheckName+']', // 결재요청명 (문서 title)
        approvalConnId: this.popupParam.safetyCheckId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    disabled() {
     return this.data.checkStatusCd === 'MCSC000015' || Boolean(this.data.sysApprovalRequestId)
    },
    isRemove() {
      return this.popupParam.safetyCheckId && Boolean(!this.data.sysApprovalRequestId)
    },
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - 240);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;

      this.today= this.$comm.getToday().slice(-2, this.$comm.getToday().length);
      this.currentMonth= this.$comm.getToday().slice(-5, this.$comm.getToday().length -3);
      if (this.today.substring(this.today, 0) === '0') {
        this.today = this.today.substring(this.today, 0)
      }
      // url setting
      this.getUrl = selectConfig.sop.safetycheck.plan.get.url;
      this.insertUrl = transactionConfig.sop.safetycheck.plan.insert.url;
      this.updateUrl = transactionConfig.sop.safetycheck.plan.update.url;
      this.deleteUrl = transactionConfig.sop.safetycheck.plan.delete.url;
      this.completeUrl = transactionConfig.sop.safetycheck.plan.complete.url;
      this.resultItemDetail = selectConfig.sop.safetycheck.result.newItem.url;
      this.lunarList = selectConfig.sop.min.equipment.result.lunar.url; // 음력 공휴일구하기
      // code setting
      // list setting
      this.search()
    },
    search() {
      this.getDetail();
    },
    setKey(data) {
      this.popupParam.safetyCheckId = data
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.safetyCheckId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.safetyCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.updateMode = true;
          this.setColumns();
        },);
      } else {
        this.datachange('A');
      }
    },
    saveData() {
      if (this.popupParam.safetyCheckId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {            
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;

              this.data.safetyCheckResultModelList = this.$_.clone(this.grid.data)

              if (this.mappingType === 'POST') {
                this.$_.forEach(this.data.safetyCheckResultModelList, _item => {
                  _item.editFlag = 'C';
                })
              }

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.safetyCheckId = result.data
      this.getDetail();
    },
    datachange(type) {
      if (type === 'A' && this.data.plantCd) {
        this.$http.url = this.$format(this.resultItemDetail, this.data.plantCd);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data.safetyCheckResultModelList = _result.data;
          this.$_.forEach(this.data.safetyCheckResultModelList, _item => {
            _item.safetyCheckResultId = uid()
          })
          this.setColumns();
        },);
      }
      else if (type === 'B' && this.data.checkDate) {
        // this.grid.data = [];
        this.setColumns();
      }
    },
    setColumns() {
      let _columns = [
        {
          name: 'checkItemName',
          field: 'checkItemName',
          label: '점검항목',
          align: 'left',
          style: 'width:450px',
          sortable: false,
          fix: true,
        },
        {
          name: 'checkItemCycleName',
          field: 'checkItemCycleName',
          label: '주기',
          align: 'center',
          style: 'width:100px',
          sortable: false,
          fix: true,
        },
      ]
      let year = this.data.checkDate.substring(0, 4);
      let month = this.data.checkDate.substring(5);

      // 주말 계산
      let date = new Date(Number(year), Number(month), 0);
      var week = ['일', '월', '화', '수', '목', '금', '토'];

      // 양력 공휴일 계산
      var solorHolidays = [ "01-01", "03-01", "05-05", "06-06", "07-17", "08-15", "10-03", "12-25"]; 

      let days = [];
      let parent = [];
      if (this.data.checkDate) {
        this.$http.url = this.$format(this.lunarList, year);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          // 음력 공휴일 계산은 백엔드에서
          this.lunarHolidays = _result.data

          for (let i=1; i<=date.getDate(); i++) {
            var dayOfWeek = week[new Date(Number(year) + '-' + Number(month) + '-' + i).getDay()];

            var solarIdx = 0;
            let countDay = 0;
            if (i < 10) {
              countDay = '0' + i
              solarIdx = solorHolidays.indexOf(month + '-0' + i);
            } else {
              countDay = i
              solarIdx = solorHolidays.indexOf(month + '-' + i);
            }
            var lunarIdx = 0;
            if (i < 10) {
              lunarIdx = this.lunarHolidays.indexOf(year + month + '0' + i);
            } else {
              lunarIdx = this.lunarHolidays.indexOf(year + month + i);
            }
            
            var day = '';
            if (dayOfWeek === '일' || dayOfWeek === '토' || solarIdx >= 0 || lunarIdx >= 0) {
              day = 'weekend'
            } else {
              day = 'weekday'
            }
            days.push({
              name: 'day' + i + 'Name',
              field: 'day' + i + 'Name',
              key: 'day' + i,
              day: i,
              label: '주',
              currentDay: String(countDay) === String(this.today) && String(month) === String(this.currentMonth) 
                        ? true : false, 
              align: 'center',
              size: 'xs',
              setHeader: true,
              style: 'width:35px',
              // dayType: day,
              headerColor: day === 'weekend' ? '#FFCDD2' : '',
              type: 'custom',
              headType: 'link',
              sortable: false,
              // component: () => import(`${'./equipmentDeteriorResultProxy.vue'}`)
            })
            days.push({
              name: 'nday' + i + 'Name',
              field: 'nday' + i + 'Name',
              key: 'nday' + i,
              day: i,
              label: '야',
              currentDay: String(countDay) === String(this.today) && String(month) === String(this.currentMonth) 
                        ? true : false, 
              align: 'center',
              size: 'xs',
              setHeader: true,
              style: 'width:35px',
              // dayType: day,
              headerColor: day === 'weekend' ? '#FFCDD2' : '',
              type: 'custom',
              headType: 'link',
              sortable: false,
              // component: () => import(`${'./equipmentDeteriorResultProxy.vue'}`)
            })
            parent.push({
              headerColor: day === 'weekend' ? '#FFCDD2' : '',
              name: String(i),
              field: String(i),
              label: String(i),
              child: days,
            })
            days = [];
          }
          this.grid.columns = this.$_.concat(_columns, parent);
          this.grid.data = this.$_.clone(this.data.safetyCheckResultModelList)

        },);
      }
          
      if (this.data.checkDate) {
        this.data.safetyCheckName = year + '년  ' + month + '월  안전관리자 안전점검일지'
      }
    },
    saveDataAppr() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.regUserId = this.$store.getters.user.userId;
          this.data.chgUserId = this.$store.getters.user.userId;

          this.data.safetyCheckResultModelList = this.$_.clone(this.grid.data)
          let promises = [
            {
              func: this.approvalValue
            },
          ];
          this.$comm.orderedPromise(promises);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.safetyCheckId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    /* eslint-disable no-unused-vars */
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getDetail();
    },
    setDeptName(deptName) {
      this.data.checkDeptName = deptName;
    }
  }
};
</script>
